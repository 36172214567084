import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';
import { useNavigate } from 'react-router-dom';
import { cleanUpBookingData, cleanUpBookingResponse } from '../../core/bookings/bookingsActions';

const SessionManager = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const INACTIVITY_TIMEOUT = 7200000; // 2 hours
    const CHECK_INTERVAL = 300000; // 5 minutes
    const { patientBookingsDetails } = useSelector((state) => state.bookings);

    useEffect(() => {
        if (!isEmpty(patientBookingsDetails)) {
            const sessionStartTime = Date.now();
            const interval = setInterval(() => {
                const sessionEndTime = Date.now();
                const diff = sessionEndTime - sessionStartTime;
                if (diff > INACTIVITY_TIMEOUT) {
                    const { messageId, methodToBook } = patientBookingsDetails;
                    navigate('/security', { state: { messageId, methodToBook } });
                    dispatch(cleanUpBookingData());
                    dispatch(cleanUpBookingResponse());
                    localStorage.clear();
                    // eslint-disable-next-line no-undef
                    sessionStorage.clear();
                }
                return null;
            }, CHECK_INTERVAL);
            return () => clearInterval(interval);
        }
        return undefined;
    }, [patientBookingsDetails]);

    return null;
};

export default SessionManager;
